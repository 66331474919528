.card {
  border-bottom: 1px solid;
}

.media-card {
  overflow: hidden;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.react-tweet-theme {
  margin: 0 !important;
}

p[class^="tweet-body_root"] {
  text-align: left
}