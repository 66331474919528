@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url(//db.onlinewebfonts.com/c/e5c490a944bc2b2746f0c851501e46c7?family=AvenirNextW10-Bold); */
/* @import url("https://fonts.googleapis.com/css2?family=Inter&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet"> */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Sora:wght@100..800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Archivo", sans-serif;
  /* background-color: #000000; */
  /* background-color: #101827; */
  /* background-color: #F6F5F2; */
  /* background-color: #FEFAF6; */
  background-color: #fff;  
  color: #030300;
}

.logo {
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Roboto", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.clearNav {
  backdrop-filter: saturate(180%) blur(5px) !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.fsac4 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 32px;
}

@media (min-width: 720px) {
  .fsac4 {
    grid-template-columns: repeat(2, 1fr);
  }
  .multi-select-items-container {
    width: 600px;
    left: -200px;
  }
}

.ktq4 {
  /* background-color: #101111; */
  /* padding: 2rem 2rem 1rem 2rem; */
  /* border-radius: 10px; */
}

.floating-date {
  position: absolute;
  bottom: 10px;
  right: 2rem;
}

.text-color-1 {
  color: #040403;
}

.text-color-2 {
  color: #030300;
}

.text-color-3 {
  color: #DAC0A3;
}

.text-color-4 {
  /* color: #102C57; */
  color: #000;
}

.header {
  z-index: 10;
  border-bottom: 1px solid #D8D8D4;
  background-color: #fff;  
}

.container {
  max-width: 1220px;
}

.text-truncate {
  width:calc(100%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.color-link {
  color: #3f6eee;
}

.color-link:hover {
  color: #f72d54;
}

.twitter-tweet.twitter-tweet-rendered{
  margin: auto;
}

.small-text {
  font-size: 14px;
  font-weight: 400;
}

.media-image-container div{
  width: 100%;
}

details[open] summary .tags, details[open] summary .view-more-link {
  display: none;
}

.bottom-shadow {
  box-shadow: 0px 25px 20px -20px rgba(0,0,0,0.45);
}